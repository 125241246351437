import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: null,
        checkboxSelection: true,
        pinned: 'left',
        sortable: false,
        filter: false,
        width: 70,
    },
    {
        headerName: 'First Name',
        field: 'firstName',
        filter: false,
    },
    {
        headerName: 'Last Name',
        field: 'lastName',
        filter: false,
    },
    {
        headerName: 'Grade',
        field: 'grade',
        filter: false,
    },
    {
        headerName: 'Id',
        field: 'id',
        filter: false,
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        filter: false,
        initialHide: true,
    },
    {
        headerName: 'Student Id',
        field: 'studentId',
        filter: false,
    },
    {
        headerName: 'Student Email',
        field: 'studentEmail',
        filter: false,
    },
    {
        headerName: 'Enrollment',
        field: 'enrollment',
        filter: false,
    },
    {
        headerName: 'Gender',
        field: 'gender',
        filter: false,
    },
    {
        headerName: 'Race',
        field: 'race',
        filter: false,
    },
    {
        headerName: 'Ethnicity',
        field: 'ethnicity',
        filter: false,
    },
    {
        headerName: timeHeader('Last Updated Date'),
        field: 'updateDate',
        filter: 'date',
    },
]
